import { Injectable } from '@angular/core';
import { LocalCacheService } from './localCache.service';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  constructor(
    private localCacheService: LocalCacheService,
    private globalService: GlobalService,
  ) {}

  pageLoad() {
    this.safeExecute(() => {
      if (!window.analytics) return;
      window.analytics.page();
    });
  }

  segmentIdentifyEventNameAndValue(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        value: values.value,
      });
    });
  }

  segmentIdentifyHomePageQuoteButton(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        tripType: values.tripType,
        selectedCountries: values.selectedCountries,
        departureDate: values.departureDate,
        returnDate: values.returnDate,
        age1: values.age1,
        age2: values.age2,
        dependentsCount: values.dependentsCount,
        state: values.state,
        promoCode: values.promoCode,
        clubMember: values.clubMember,
        valid: values.valid,
      });
    });
  }

  segmentSingleTripOnChangePlanType(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        tripLength: values.tripLength,
        planType: values.planType,
        excessAmount: values.excessAmount,
        cruise: values.cruise,
        ski: values.ski,
        retailPrice: values.retailPrice,
        selectedpricedetails: values.selectedPriceDetails,
      });
    });
  }

  segmentIdentifyUpdateQuote(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        countries: values.selectedCountries,
        depart: values.departureDate,
        return: values.returnDate,
        age1: values.age1,
        age2: values.age2,
        dependentsCount: values.dependentsCount,
        state: values.state,
        promoCode: values.promoCode,
        clubMember: values.clubMember,
        tripType: values.tripType,
      });
    });
  }

  segmentQuoteTripPlanDetails(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        tripLength: values.tripLength,
        planType: values.planType,
        excessAmount: values.excessAmount,
        cruise: values.cruise,
        ski: values.ski,
        retailPrice: values.retailPrice,
        countries: values.selectedCountries,
        depart: values.departureDate,
        return: values.returnDate,
        age1: values.age1,
        age2: values.age2,
        dependentsCount: values.dependentsCount,
        state: values.state,
        promoCode: values.promoCode,
        tripType: values.tripType,
        selectedPriceDetails: values.selectedPriceDetails,
      });
    });
  }

  segmentTravellerDetailsAndConditionsEvent() {
    this.safeExecute(() => {
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const selectedCoverDetail = this.localCacheService.getSessionStorage(
        'selectedCoverDetail',
      );

      const dependentTravellers = travelerDetails.dependents.map(
        (dep: any) => ({
          dependentTravelLastName: dep.lastName,
          dependentTravelFirstName: dep.firstName,
          dependentTravelDateOfBirth: dep.dateOfBirth,
          dependentTravelExstMedCon: dep.medConSelected,
        }),
      );

      window.analytics.track('step_two_traveller_details', {
        club: environment.autoClub,
        exstMedicalCondition: travelerDetails.medical_con,
        quoteId: quoteDetail?.quoteId ?? 'no quoteId',
        quoteNumber: quoteDetail?.quoteNumber ?? 'no quoteNumber',

        //Primary Traveller Details
        primaryTravellerLastName: travelerDetails.adult1.lastName,
        primaryTravellerFirstName: travelerDetails.adult1.firstName,
        primaryTravellerDateOfBirth: travelerDetails.adult1.dateOfBirth,
        primaryTravellerExstMedCon: travelerDetails.adult1.one_med_con,
        primaryTravellerEmailAdd: travelerDetails.contactAndAddressDetails,
        primaryTravellerReEnterEmailAdd:
          travelerDetails.contactAndAddressDetails.emailId,
        primaryTravellerMobileNumber:
          travelerDetails.contactAndAddressDetails.confEmailId,
        primaryTravellerStreetAddress:
          travelerDetails.contactAndAddressDetails.address,
        primaryTravellerSuburb: travelerDetails.contactAndAddressDetails,
        primaryTravellerAddressState:
          travelerDetails.contactAndAddressDetails.state,
        primaryTravellerPostalCode:
          travelerDetails.contactAndAddressDetails.postalCode,

        //Secondary Traveller Details
        secondaryTravellerLastName: travelerDetails.adult2?.lastName,
        secondaryTravellerFirstName: travelerDetails.adult2?.firstName,
        secondaryTravellerDateOfBirth: travelerDetails.adult2?.dateOfBirth,
        secondaryTravellerExstMedCon: travelerDetails.adult2?.two_med_con,

        //Dependent Traveller Details
        dependentTravelDetails: dependentTravellers,
        //Selected price details
        selectedPriceDetails: selectedCoverDetail?.selectedPrice,
      });
    });
  }

  segmentMedicalAssessmentDetails(values: any) {
    this.safeExecute(() => {
      window.analytics.track(values.eventName, {
        club: environment.autoClub,
        currentAssessment: values.currentAssessment,
        medicalCondition: values.medicalCondition,
        lastName: values.lastName,
        firstName: values.firstName,
        dateOfBirth: values.dateOfBirth,
      });
    });
  }

  segmentConfirmTravelDetails() {
    this.safeExecute(() => {
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const quoteEnquiry =
        this.localCacheService.getSessionStorage('quoteEnquiry');
      let dependentTravellers = [];
      const selectedCoverDetail = this.localCacheService.getSessionStorage(
        'selectedCoverDetail',
      );

      if (quoteEnquiry.dependentsCount > 0) {
        dependentTravellers = travelerDetails.dependents.map((dep: any) => ({
          dependentTravelLastName: dep.lastName,
          dependentTravelFirstName: dep.firstName,
          dependentTravelDateOfBirth: dep.dateOfBirth,
          dependentTravelExstMedCon: dep.medConSelected,
        }));
      }

      window.analytics.track('step_three_confirm_travel_details', {
        club: environment.autoClub,
        exstMedicalCondition: travelerDetails.medical_con,
        quoteId: quoteDetail?.quoteId ?? 'no quoteId',

        //Primary Traveller Details
        primaryTravellerLastName: travelerDetails.adult1.lastName,
        primaryTravellerFirstName: travelerDetails.adult1.firstName,
        primaryTravellerDateOfBirth: travelerDetails.adult1.dateOfBirth,
        primaryTravellerExstMedCon: travelerDetails.adult1.one_med_con,

        //Secondary Traveller Details
        secondaryTravellerLastName: travelerDetails.adult2?.lastName,
        secondaryTravellerFirstName: travelerDetails.adult2?.firstName,
        secondaryTravellerDateOfBirth: travelerDetails.adult2?.dateOfBirth,
        secondaryTravellerExstMedCon: travelerDetails.adult2?.two_med_con,

        //Dependent Traveller Details
        dependentTravelDetails: dependentTravellers,

        //Contact Details Primary Traveller
        primaryTravellerEmailAdd: travelerDetails.contactAndAddressDetails,
        primaryTravellerReEnterEmailAdd:
          travelerDetails.contactAndAddressDetails.emailId,
        primaryTravellerMobileNumber:
          travelerDetails.contactAndAddressDetails.confEmailId,
        primaryTravellerStreetAddress:
          travelerDetails.contactAndAddressDetails.address,
        primaryTravellerSuburb: travelerDetails.contactAndAddressDetails,
        primaryTravellerAddressState:
          travelerDetails.contactAndAddressDetails.state,
        primaryTravellerPostalCode:
          travelerDetails.contactAndAddressDetails.postalCode,

        //Policy Details
        quoteNumber: quoteDetail?.quoteNumber ?? 'no quoteNumber',
        destination: quoteDetail.destinations,
        tripType: quoteEnquiry.tripType,
        travelDepartDate: quoteEnquiry.depart,
        travelReturnDate: quoteEnquiry.return,
        cruise: quoteDetail.isCruise,
        ski: quoteDetail.isSking,
        ageTravellers: quoteDetail.ages,
        dependentCount: quoteEnquiry.dependentsCount,
        topCoverPolicy: quoteDetail.premiums.prodcct,
        policyExcess: quoteDetail.premiums.excess,
        cruiseAmount: quoteDetail.premiums.cruise,
        skiAmount: quoteDetail.premiums.skiing,
        preExMedical: quoteDetail.premiums.medical,
        gst: quoteDetail.premiums.gst,
        stampDuty: quoteDetail.premiums.stampDuty,
        totalAmount: quoteDetail.premiums.totalCost,
        //Selected price details
        selectedPriceDetails: selectedCoverDetail?.selectedPrice,
      });
    });
  }

  segmentPaymentPolicyDetails() {
    this.safeExecute(() => {
      const travelerDetails =
        this.localCacheService.getSessionStorage('travelerDetails');
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      const quoteEnquiry =
        this.localCacheService.getSessionStorage('quoteEnquiry');
      const primaryFullName = `${travelerDetails.adult1?.firstName} ${travelerDetails.adult1?.lastName}`;
      const secondaryFullName = `${travelerDetails.adult2?.firstName} ${travelerDetails.adult2?.firstName}`;
      let dependentTravellers = '';

      if (quoteEnquiry.dependentsCount > 0) {
        dependentTravellers = travelerDetails.dependents?.map(
          (dep: any) => `${dep.firstName} ${dep.lastName}`,
        );
      }

      const travellerNames = [
        primaryFullName,
        secondaryFullName ?? '',
        dependentTravellers,
      ].join(', ');

      window.analytics.track('step_four_payment_policy_details', {
        club: environment.autoClub,
        quoteId: quoteDetail?.quoteId ?? 'no quoteId',
        quoteNumber: quoteDetail?.quoteNumber ?? 'no quoteNumber',
        destination: quoteDetail.destinations,
        tripType: quoteEnquiry.tripType,
        travelDepartDate: quoteEnquiry.depart,
        travelReturnDate: quoteEnquiry.return,
        cruise: quoteDetail.isCruise,
        ski: quoteDetail.isSking,
        ageTravellers: quoteDetail.ages,
        dependentCount: quoteEnquiry.dependentsCount,
        nameOfTravellers: travellerNames,
        topCoverPolicy: quoteDetail.premiums?.prodcct,
        policyExcess: quoteDetail.premiums?.excess,
        cruiseAmount: quoteDetail.premiums?.cruise,
        skiAmount: quoteDetail.premiums?.skiing,
        preExMedical: quoteDetail.premiums?.medical,
        gst: quoteDetail.premiums?.gst,
        stampDuty: quoteDetail.premiums?.stampDuty,
        totalAmount: quoteDetail.premiums?.totalCost,
      });
    });
  }

  segmentIdentifyIdWithQuoteId() {
    this.safeExecute(() => {
      const quoteDetail =
        this.localCacheService.getSessionStorage('quoteDetail');
      window.analytics.track('identifyIdWithQuoteId', {
        //Quote Id
        club: environment.autoClub,
        quoteId: quoteDetail?.quoteId ?? 'no quoteId',
        quoteNumber: quoteDetail?.quoteNumber ?? 'no quoteNumber',
      });
      window.analytics.identify({
        //Quote Id
        club: environment.autoClub,
        eventName: 'identifyIdWithQuoteId',
        quoteId: quoteDetail?.quoteId ?? 'no quoteId',
        quoteNumber: quoteDetail?.quoteNumber ?? 'no quoteNumber',
      });
    });
  }

  segmentAff_BranchEvent() {
    this.safeExecute(() => {
      const aff_branchObj = this.globalService.getUrlParamsToJson();
      aff_branchObj.club = environment.autoClub;
      window.analytics.track('Affiliate_Branch', aff_branchObj);
    });
  }

  segmentCreate_Purchase(obj: any) {
    this.safeExecute(() => {
      window.analytics.track('Create_Purchase', obj);
    });
  }
  segmentSend_Policy() {
    this.safeExecute(() => {
      window.analytics.track('Send_Policy', 'Sent');
    });
  }

  safeExecute(fn: any) {
    try {
      fn();
    } catch (error) {
      console.error('An error occurred in the segment analytics code:', error);
    }
  }
}
